<template>
  <div class="record">
    <page-title />
    <div class="record-box">
      <p class="record-title">抽奖记录</p>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="(item, index) in list"
          :key="index"
          icon="star-o"
          :value="item.createTime"
        >
          <template #title>
            <van-tag v-if="item.status === '2'" type="primary">未中奖</van-tag>
            <van-tag v-else type="danger">
              中奖{{ item.amount / 100 }}元
            </van-tag>
          </template>
        </van-cell>
      </van-list>
      <div style="height: 3vh"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Record',
  data() {
    return {
      rankList: [],
      list: [],
      loading: false,
      finished: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {},
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
      this.$http({
        url: '/xt-exam/lottery/getLottery',
        method: 'get'
      }).then(response => {
        if (response.code == 200) {
          console.log(response)
          this.list = response.data
          this.finished = true
        }
      })
    }
    // getRankList(type) {
    //   if (type === 'week') {
    //     return this.$http({
    //       url: '/xt-exam/etUser/weekRank',
    //       params: {
    //         type
    //       }
    //     })
    //   } else {
    //     return this.$http({
    //       url: '/xt-exam/etUser/allRank',
    //       params: {
    //         type
    //       }
    //     })
    //   }
    // },
    // init() {
    //   this.getRankList().then(response => {
    //     if (response.code == 200) {
    //       this.rankList = response.data
    //     }
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.record {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.record-box {
  position: relative;
  flex: 1;
  background: #ffffff;
  padding: 0 6.4vw;
}

.record-title {
  font-size: 1.49rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  margin: 0;
  margin-top: 3vh;
  margin-bottom: 1vh;
  padding-bottom: 1vh;
  border-bottom: 1px solid #010e4f;
}
</style>
